/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          dynamicTagCondition => method to define if the tag should be displayed
          dynamicTagLabel => method to display some text based on certain evaluations
          dynamicTagColor => method to apply on badge element based on certain evaluations
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
    {
      url: '/programa/inicio',
      name: 'Inicio',
      icon: 'HomeIcon'
    },
    {
      header: 'REPORTES',
      icon: 'SettingsIcon',
      condition: '',
      items: [
        {
          url: '/programa/avance',
          name: 'Avance',
          icon: 'TrendingUpIcon'
        },
        {
          url: '/programa/performance',
          name: 'Operativo',
          icon: 'BarChart2Icon'
        },
        {
          url: '/programa/demografico',
          name: 'Demográfico',
          icon: 'UsersIcon'
        },
        {
          url: '/programa/cobranza',
          name: 'Desempeño de cobranza',
          icon: 'DollarSignIcon'
        },
        {
          url: '/programa/efectividad',
          name: 'Efectividad',
          icon: 'CheckSquareIcon'
        },
        // {
        //   url: '/programa/#',
        //   name: 'Generación solar',
        //   icon: 'SunIcon'
        // },
      ]
    },
    {
      header: 'PROSPECTOS',
      icon: 'SettingsIcon',
      condition: '',
      items: [
        {
          url: '/programa/lista-de-prospectos',
          name: 'Listado de prospectos',
          icon: 'ClipboardIcon',
          dynamicTagCondition: 'need_to_show_program_notes_to_read',
          dynamicTagLabel: 'program_notes_to_read',
          dynamicTagColor: 'new_impacta_mas_chip_color',
          needPulse: true,
        },
      ]
    },
    {
      header: 'PROYECTOS',
      icon: 'SettingsIcon',
      condition: '',
      items: [
        {
          url: '/programa/proyectos-en-proceso',
          name: 'Listado de proyectos',
          icon: 'ClipboardIcon'
        },
      ]
    },
    {
      header: 'HERRAMIENTAS',
      icon: 'SettingsIcon',
      condition: '',
      items: [
        {
          url: '/programa/informacion-de-apoyo',
          name: 'Información de apoyo',
          icon: 'BookIcon'
        },
      ]
    },
    {
      header: 'Configuración',
      icon: 'SettingsIcon',
      condition: 'show_config_module',
      items: [
        {
          url: '/programa/catalogo-zonas-y-regiones',
          name: 'Zonas y regiones',
          icon: 'MapIcon',
          condition: 'show_zone_regions'
        },
        {
          url: '/programa/usuarios',
          name: 'Usuarios',
          icon: 'UserIcon',
          condition: 'show_users_admin'
        }
      ]
    },
  ]