
<template>
  <div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">
    <vx-tour :steps="steps" v-if="!disableThemeTour && (windowWidth >= 1200 && mainLayoutType === 'vertical' && verticalNavMenuWidth == 'default')" />

    <the-customizer
      v-if                    = "!disableCustomizer"
      :footerType             = "footerType"
      :hideScrollToTop        = "hideScrollToTop"
      :navbarType             = "navbarType"
      :navbarColor            = "navbarColor"
      :routerTransition       = "routerTransition"
      @toggleHideScrollToTop  = "toggleHideScrollToTop"
      @updateFooter           = "updateFooter"
      @updateNavbar           = "updateNavbar"
      @updateNavbarColor      = "updateNavbarColor"
      @updateRouterTransition = "updateRouterTransition" />

    <help-center
      :footerType             = "footerType"
      :hideScrollToTop        = "hideScrollToTop"
      :navbarType             = "navbarType"
      :navbarColor            = "navbarColor"
      :routerTransition       = "routerTransition"
      @toggleHideScrollToTop  = "toggleHideScrollToTop"
      @updateFooter           = "updateFooter"
      @updateNavbar           = "updateNavbar"
      @updateNavbarColor      = "updateNavbarColor"
      @updateRouterTransition = "updateRouterTransition" />

    <v-nav-menu
      :navMenuItems = "navMenuItems"
      title         = "RedGirasol"
      parent        = ".layout--main" />

    <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
      <div id="content-overlay" />

    <!-- Navbar -->
    <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
      <the-navbar-horizontal
        :navbarType= "navbarType"
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" />

      <div style="height: 62px" v-if="navbarType === 'static'"></div>

      <h-nav-menu
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]"
        :navMenuItems="navMenuItems" />
    </template>

    <template v-else>
      <the-navbar-vertical
        :navbarColor="navbarColor"
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" />
    </template>
    <!-- /Navbar -->

      <div class="content-wrapper">

        <div class="router-view">
          <div class="router-content">

            <transition :name="routerTransition">

              <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                <div
                  class="content-area__heading"
                  :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <vx-breadcrumb class="ml-4 md:block hidden" v-if="vxBreadcrumb" root="/proveedor" :breadcrumb="vxBreadcrumb" :isRTL="$vs.rtl" />

              </div>
            </transition>

            <div class="content-area__content">

              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle" @setActiveBreadcrumbTitle="setActiveBreadcrumbTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
              </transition>
            </div>
          </div>
        </div>
      </div>
      <calendar-popup v-if="showCalendar" />
      <feedback-popup v-if="showCalendar" />
      <switch-account-popup />
      <app-footer :year="currentYear()" :footer_text="footerText" :is_program="isProgram" :program_info="currentProgramData" />
    </div>
  </div>
</template>


<script>
import HNavMenu            from '../components/horizontal-nav-menu/HorizontalNavMenu.vue'
import kofNavMenuItems        from '../components/vertical-nav-menu/kofNavMenuItems.js'
import programCommercialManagersNavMenuItem   from '../components/vertical-nav-menu/programCommercialManagersNavMenuItem.js'
import TheCustomizer       from '../components/customizer/TheCustomizer.vue'
import HelpCenter          from '@views/programs/kof/components/HelpCenter.vue'
import TheNavbarHorizontal from '../components/navbar/TheNavbarHorizontal.vue'
import TheNavbarVertical   from '../components/navbar/TheNavbarVertical.vue'
import AppFooter           from '../components/AppFooter.vue'
import themeConfig        from '@/../themeConfigKOF.js'
import VNavMenu            from '../components/vertical-nav-menu/VerticalNavMenu.vue'
import CalendarPopup       from '../../components/CalendarPopup.vue'
import FeedbackPopup       from '../../components/FeedbackPopup.vue'
import SwitchAccountPopup from "@components/SwitchAccountPopup";
//import GeneralLogPopup     from '../../components/GeneralLogPopup.vue'

const VxTour = () => import('../../vx-components/VxTour.vue')
import '@assets/css/kof.css'

export default {
  components: {
    HNavMenu,
    TheCustomizer,
    HelpCenter,
    AppFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
    VxTour,
    CalendarPopup,
    FeedbackPopup,
    SwitchAccountPopup,
    programCommercialManagersNavMenuItem,
    //GeneralLogPopup
  },
  data () {
    return {
      disableCustomizer : themeConfig.disableCustomizer,
      disableThemeTour  : themeConfig.disableThemeTour,
      dynamicWatchers   : {},
      footerType        : themeConfig.footerType  || 'static',
      hideScrollToTop   : themeConfig.hideScrollToTop,
      isNavbarDark      : false,
      navbarColor       : themeConfig.navbarColor || '#fff',
      navbarType        : themeConfig.navbarType  || 'floating',
      routerTransition  : themeConfig.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle,
      steps: [
        {
          target  : '#btnVNavMenuMinToggler',
          content : 'Toggle Collapse Sidebar.'
        },
        {
          target  : '.vx-navbar__starred-pages',
          content : 'Create your own bookmarks. You can also re-arrange them using drag & drop.'
        },
        {
          target  : '.i18n-locale',
          content : 'You can change language from here.'
        },
        {
          target  : '.navbar-fuzzy-search',
          content : 'Try fuzzy search to visit pages in flash.'
        },
        {
          target  : '.customizer-btn',
          content : 'Customize template based on your preference',
          params  : {
            placement: 'left'
          }
        },
        {
          target  : '.vs-button.buy-now',
          content : 'Buy this awesomeness at affordable price!',
          params  : {
            placement: 'top'
          }
        }
      ],
      vxBreadcrumb: null,
      footerText: ""
    }
  },
  async mounted() {
    await this.setFooterText();
  },
  watch: {
    '$route' () {
      this.routeTitle = this.$route.meta.pageTitle;
      this.composeBreadcrumb();
    },
    isThemeDark (val) {
      const color = this.navbarColor === '#fff' && val ? '#10163a' : '#fff'
      this.updateNavbarColor(color)
    },
    '$store.state.mainLayoutType' (val) {
      this.setNavMenuVisibility(val)
      this.disableThemeTour = true
    }
  },
  computed: {
    needHelpCenter() {
      let componentName =  this.$router.currentRoute.name;
      if(componentName === 'inicioKOF' || componentName === 'pgmPotentialApplicants') {
        return true;
      }
      return false;
    },
    isProgram(){
      return this.$acl.check('isProgram');
    },
    bodyOverlay () { return this.$store.state.bodyOverlay },    
    contentAreaClass () {
      if (this.mainLayoutType === 'vertical') {
        if      (this.verticalNavMenuWidth === 'default') return 'content-area-reduced'
        else if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg'
        else return 'content-area-full'
      } else return 'content-area-full'
    },
    footerClasses () {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static'
      }
    },
    isAppPage () {
      return this.$route.meta.no_scroll
    },
    isThemeDark ()     { return this.$store.state.theme === 'dark' },
    layoutTypeClass () { return `main-${this.mainLayoutType}`      },
    mainLayoutType ()  { return this.$store.state.mainLayoutType   },
    navbarClasses ()   {
      return {
        'navbar-hidden'   : this.navbarType === 'hidden',
        'navbar-sticky'   : this.navbarType === 'sticky',
        'navbar-static'   : this.navbarType === 'static',
        'navbar-floating' : this.navbarType === 'floating'
      }
    },
    verticalNavMenuWidth () { return this.$store.state.verticalNavMenuWidth },
    windowWidth ()          { return this.$store.state.windowWidth },
    navMenuItems(){
      if (this.$acl.check('isProgramAdmin')){
        return kofNavMenuItems;
      }
      else if(this.$acl.check('isExecutiveManager') || this.$acl.check('isProgramCommercialManager')){
        return programCommercialManagersNavMenuItem;
      }
      else {
        return [];
      }
    },
    showGeneralLog(){
      return this.$acl.check('isExecutiveManager');
    },

    /* items condicionados */
    showCalendar(){
      return this.SupplierStatus >= 4;
    },
  },
  methods: {
    changeRouteTitle (title) {
      this.routeTitle = title
    },
    updateNavbar (val) {
      if (val === 'static') this.updateNavbarColor(this.isThemeDark ? '#10163a' : '#fff')
      this.navbarType = val
    },
    updateNavbarColor (val) {
      this.navbarColor = val
      if (val === '#fff') this.isNavbarDark = false
      else this.isNavbarDark = true
    },
    updateFooter (val) {
      this.footerType = val
    },
    currentYear(){
      return new Date().getFullYear();
    },
    async setFooterText() {
      await axios.get('/api/public-footer-text').then((response) => {
          this.footerText = response.data;
      });
    },
    updateRouterTransition (val) {
      this.routerTransition = val
    },
    setNavMenuVisibility (layoutType) {
      if ((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === 'vertical' && this.windowWidth < 1200)) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
      } else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      }
    },
    toggleHideScrollToTop (val) {
      this.hideScrollToTop = val
    },
    setActiveBreadcrumbTitle(title){
      this.vxBreadcrumb.find(f => f.active).title = title;
    },
    composeBreadcrumb() {
      let route = this.$route;
      let bread = [];
      if(route.meta.breadcrumb){
        route.meta.breadcrumb.forEach(rlink => {
          let link = {url: rlink.url, title: rlink.title, active: rlink.active };
          if(link.url && link.url.includes('{')){
            link.url = this.replaceParams(route, link.url);
          }
          if(link.title && link.title.includes('{')){
            link.title = this.replaceParams(route, link.title);
          }
          bread.push(link);
        });
        this.vxBreadcrumb = bread;
      }
      else {
        this.vxBreadcrumb = null;
      }
    },
    replaceParams(route, term){
      let found = [], rxp = /{([^}]+)}/g, str = term, curMatch;
      while( curMatch = rxp.exec( str )) {
        found.push( curMatch[1] );
      }
      let nl = term;
      found.forEach(l => {              
        nl = nl.replace(`{${l}}`, route.params[l]);
      });
      return nl;
    }
  },
  created () {
    const color = this.navbarColor === '#fff' && this.isThemeDark ? '#10163a' : this.navbarColor
    this.updateNavbarColor(color)
    this.setNavMenuVisibility(this.$store.state.mainLayoutType)

    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch('$store.state.windowWidth', (val) => {
      if (val < 1200) {
        this.disableThemeTour = true
        this.dynamicWatchers.windowWidth()
      }
    })

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch('$store.state.verticalNavMenuWidth', () => {
      this.disableThemeTour = true
      this.dynamicWatchers.verticalNavMenuWidth()
    })

    this.dynamicWatchers.rtl = this.$watch('$vs.rtl', () => {
      this.disableThemeTour = true
      this.dynamicWatchers.rtl()
    })

    this.composeBreadcrumb();
  },
  beforeDestroy () {
    Object.keys(this.dynamicWatchers).map(i => {
      this.dynamicWatchers[i]()
      delete this.dynamicWatchers[i]
    })
  }
}

</script>
