<template>
    <div id="home-article">
        <div class="vx-row">
            <div class="vx-col w-full">
                <vx-card no-shadow>
                    <h4 class="mb-2 rgh-title">Reporte de <span>efectividad</span>.</h4>
                    <small>Se muestra el % de tasa de conversión, así como los tiempos promedio en días para cada una de las etapas del Prospecto..</small>
                </vx-card>
                <vs-divider></vs-divider>
            </div>
            <div class="vx-col w-full">
                <vx-card no-shadow>
                    <h4 class="mb-2 rgh-title "><span>Filtros</span>.</h4>
                    <small>
                        Para visualizar el listado, será necesario utilizar el filtrado que permite consultar la información de efectividad de los participantes del programa. Una vez elegidas las opciones, con un clic en el botón de búsqueda (lupa), te desplegará el listado correspondiente.
                    </small>
                </vx-card>
                <vs-divider></vs-divider>
            </div>
            <div class="vx-col w-full">
                <vx-card no-shadow>
                    <h4 class="mb-2 rgh-title ">Tabla de <span>efectividad</span>.</h4>
                    <small class="mb-2">
                        El listado arrojado brinda información de los la efectividad de cada rubro filtrado, tomando en cuenta el tiempo de atención y ejecución de cada uno de los pasos del ciclo de RedGirasol.
                    </small><br>
                    <small>
                        <ul class="mt-2">
                            <li class="mb-2">
                                <strong>"Tiempo sin contacto"</strong>
                                <br> - <u>¿Qué mide?</u> Tiempo que tarda desde que se asigna al instalador hasta que cambia a un estado que indica que fue contactado.
                                <br> - <u>¿Para qué?</u> Este indicador nos servirá para identificar qué tanto demora el instalador en llevar a cabo el primer contacto con el prospecto, pedimos no más de 7 días para contactarlo y 14 para visitarlo desde que se le asigna.
                            </li>
                            <li class="mb-2">
                                <strong>"Tiempo que toma descartar"</strong>
                                <br> - <u>¿Qué mide?</u> Tiempo promedio que toma descartar un cliente desde que se asignó, hasta que haya sido descartado, habiendo pasado por cualquier estado.
                                <br> - <u>¿Para qué?</u> Medición con la que no contábamos, nos podrá reflejar los tiempos promedios en que se descarta un proyecto, así mostrar una foto de los que se estén descartando desde el inicio o que hayan pasado el proceso de propuesta. No permitirá también limitar al instalador para buscar cerrar o descartar a aquellos que no.
                            </li>
                            <li class="mb-2">
                                <strong>"Tiempo que tomó obtener interés"</strong>
                                <br> - <u>¿Qué mide?</u> Tiempo promedio que toma un prospecto en ser contactado y mostrar interés.
                                <br> - <u>¿Para qué?</u> Identificar el tiempo promedio que demora prospecto en mostrar interés, demuestra principamente el tiempo que demora el instalador en contactar al prospecto, pero solo a los interesados. 
                            </li>
                            <li class="mb-2">
                                <strong>"Tiempo que toma entregar propuesta al interesado"</strong>
                                <br> - <u>¿Qué mide?</u> Tiempo promedio que demora el instalador en entregar propuesta una vez que el cliente esté interesado.
                                <br> - <u>¿Para qué?</u> Este indicador permitirá identificar aquellos instaladores que demoran en dar seguimiento a los prospectos interesados ya sea por falta de visita o solo demora en entregar propuesta.Tiempo máximo para entregar propuesta una vez que mostró interés es de 7 días.
                            </li>
                            <li class="mb-2">
                                <strong>"Tiempo que pasa desde propuesta /interesado hasta solicitud"</strong>
                                <br> - <u>¿Qué mide?</u> Tiempo promedio que demora un instalador en ingresar la solicitudes de los clientes despúes de entregar propuesta
                                <br> - <u>¿Para qué?</u> Medición que nos permitirá leer el tiempo promedio de los interesados en convencerse/recopilar todo para iniciar el proceso de solicitud. No se contempla tiempo  límite.
                            </li>
                            <li class="mb-2">
                                <strong>"Tiempo que tomó aprobar"</strong>
                                <br> - <u>¿Qué mide?</u> Tiempo desde el ingreso de solicitud hasta la aprobación
                            </li>
                        </ul>
                    </small>
                </vx-card>
                <vs-divider></vs-divider>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'dashboard-effectiveness-article',
}
</script>

<style>

</style>