<template>
    <div id="home-article">
        <div class="vx-row">
            <div class="vx-col w-full">
                <vx-card no-shadow>
                    <h4 class="mb-2 rgh-title">Listado de <span>prospectos</span>.</h4>
                    <small>La sección Listado de Prospectos la podrás utilizar para visualizar la información de los prospectos registrados a los que tienes acceso.</small>
                </vx-card>
                <vs-divider></vs-divider>
            </div>
            <div class="vx-col w-full">
                <vx-card no-shadow>
                    <h4 class="mb-2 rgh-title "><span>Filtros</span>.</h4>
                    <small>
                        Para visualizar el listado, será necesario utilizar el filtrado que permite elegir la zona o región a la que tienes acceso, incluyendo  la selección de “Todas”. Una vez elegidas las opciones, con un clic en el botón de búsqueda (lupa), te desplegará el listado correspondiente.
                    </small>
                </vx-card>
                <vs-divider></vs-divider>
            </div>
            <div class="vx-col w-full">
                <vx-card no-shadow>
                    <h4 class="mb-2 rgh-title ">Listado de <span>prospectos</span>.</h4>
                    <small class="mb-2">
                        El listado arrojado brinda información de los prospectos cargados pertenecientes a la zona y región seleccionada en el filtrado, además de campos para ejecutar acciones:
                    </small>
                    <small>
                        <ul class="mt-2">
                            <li class="mb-2">
                                <strong>"Cliente"</strong>: Es el número identificador que este cliente tiene con {{currentProgramData.name}}.
                            </li>
                            <li class="mb-2">
                                <strong>"Nombre Cliente”</strong>: Es el nombre de su tienda.
                            </li>
                            <li class="mb-2">
                                <strong>"Prospecto”</strong>: Nombre de la persona encargada de la tienda o quien tendrá seguimiento del proyecto, además de su número telefónico. Este campo cuenta con información adicional, al dar clic, se despliega en la parte inferior el domicilio de la tienda; adicional a esto, se encuentra un vínculo de “Ubicación”, el cuál permite abrir en la aplicación Google Maps la ubicación geográfica del lugar, siempre y cuando se haya registrado con la información correspondiente.
                            </li>
                            <li class="mb-2">
                                <strong>"Zona / Región"</strong>: Muestra la zona y región de {{currentProgramData.name}} a la que pertenece.
                            </li>
                            <li class="mb-2">
                                <strong>"Responsable"</strong>: ID, nombre comercial, correo electrónico y teléfono de la empresa instaladora que tiene asignada el prospecto para su seguimiento. <br>En caso de que el instalador haya asignado el seguimiento a alguno de sus agentes, aparecerá un botón rojo, al dar clic, muestra nombre , correo electrónico y teléfono del agente quien está dando seguimiento.
                            </li>
                            <li class="mb-2">
                                <strong>"Estado"</strong>: Situación en la que se encuentra el seguimiento del prospecto. Cuenta con información adicional, además de funciones posibles de acuerdo al usuario. Al dar clic, arroja un modal con dos secciones, “Línea del tiempo” es un registro de los cambios de estado del prospecto durante el tiempo; “Actualizar estado” permite modificar el estado en que se encuentra el prospecto, el cual se encontrará habilitado solo para los usuarios con los roles competente.
                            </li>
                            <li class="mb-2">
                                <strong>"Acciones"</strong>: Este campo cuenta con 2 acciones a realizar, que estarán habilitadas solo para los usuarios con los roles competentes.
                                <ul class="mt-2">
                                    <li class="ml-2 mb-2">
                                        <strong>"Editar prospecto"</strong>:  Al dar clic, arroja la información del prospecto en diferentes campos para poder modificarla.
                                    </li>
                                    <li class="ml-2 mb-2">
                                        <strong>"Notas del prospecto"</strong>: Al dar clic, abre un campo de texto para escribir y guardar notas sobre el seguimiento del prospecto, además de mostrar el historial de las notas que se han guardado durante la vida del prospecto en la plataforma, estas notas son visibles para todos los usuarios con acceso al prospecto.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </small>
                </vx-card>
                <vs-divider></vs-divider>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'potential-applicants-list-article',
}
</script>

<style>

</style>