<template>
    <div id="home-article">
        <div class="vx-row">
            <div class="vx-col w-full">
                <vx-card no-shadow>
                    <h4 class="mb-2 rgh-title"><span>Inicio</span>.</h4>
                    <small>En la sección de Inicio encuentras 3 apartados informativos.</small>
                </vx-card>
                <vs-divider></vs-divider>
            </div>
            <div class="vx-col w-full">
                <vx-card no-shadow>
                    <h4 class="mb-2 rgh-title ">Tasa de <span>conversión</span>.</h4>
                    <small>
                        <ul>
                            <li class="mb-2">
                                <strong>“Prospectos”</strong>: Cantidad de prospectos dados de alta en la plataforma.
                            </li>
                            <li class="mb-2">
                                <strong>“Proyectos instalados”</strong>: Cantidad de prospectos registrados que ya tienen sus paneles instalados y funcionando.
                            </li>
                            <li class="mb-2">
                                <strong>“Tasa de conversión”</strong>: Porcentaje de efectividad de proyectos instalados entre la cantidad de prospectos registrados.
                            </li>
                        </ul>
                    </small>
                </vx-card>
                <vs-divider></vs-divider>
            </div>
            <div class="vx-col w-full">
                <vx-card no-shadow>
                    <h4 class="mb-2 rgh-title">Financiamiento y generación <span>solar</span>.</h4>
                    <small>
                        <ul>
                            <li class="mb-2">
                                <strong>“Monto financiado”</strong>: Cantidad en pesos mexicanos que se ha financiado entre todos los proyectos instalados registrados en el sistema.
                            </li>
                            <li class="mb-2">
                                <strong>"Generación solar gradual”</strong>:  Energía generada estimada por los proyectos instalados desde el comienzo de su funcionamiento a la fecha.
                            </li>
                        </ul>
                    </small>
                </vx-card>
                <vs-divider></vs-divider>
            </div>
            <div class="vx-col w-full">
                <vx-card no-shadow>
                    <h4 class="mb-2 rgh-title">Datos de <span>impacto</span>.</h4>
                    <small>
                        <ul>
                            <li class="mb-2">
                                <h6 class="mb-2"><strong>Proyectada a 25 años</strong></h6>
                                <ul>
                                    <li class="mb-2">
                                        <strong>"Ambiental"</strong>: Proyección estimada de emisiones de toneladas de CO2 evitadas  por la generación de energía solar durante 25 años.
                                    </li>
                                    <li class="mb-2">
                                        <strong>"Económico"</strong>: Proyección estimada del ahorro en pesos por la generación de energía solar durante 25 años.
                                    </li>
                                </ul>
                            </li> 
                            <li class="mb-2">
                                <h6 class="mb-2"><strong>Gradual</strong></h6>
                                <ul>
                                    <li class="mb-2">
                                        <strong>"Ambiental"</strong>: Estimación de emisiones de toneladas de CO2 evitadas  por la generación de energía solar desde el comienzo de su funcionamiento a la fecha.
                                    </li>
                                    <li class="mb-2">
                                        <strong>"Económico"</strong>:  Proyección estimada del ahorro en pesos por la generación de energía solar desde el comienzo de su funcionamiento a la fecha.
                                    </li>
                                </ul>
                            </li> 
                        </ul>
                    </small>
                </vx-card>
                <vs-divider></vs-divider>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'home-article',
}
</script>

<style>

</style>